import React, { useState } from "react";
import axios from "axios";
import Apiendpoints from "../ApiEndpoints";
import "./css/LoaderScreen.css";
import Loader from "react-js-loader";

export default function LoaderScreen(props) {

	return <>
		<div className="loaderScreenArea">
			<div className="loaderContainer">
				<Loader type="spinner-circle" bgColor={"gold"} color={"gold"} title={"Rupin AI"} size={100} />
			</div>
		</div>
	</>
}


import React, { useEffect, useState } from "react";
import ContactUs from "../components/ContactUs";
import Header from "../components/Header";
import "./css/Products.css";
import Footer from "../components/Footer";
import ProductDescriptionModal from "../components/productDescriptionModal";

export default function Products() {

	const [formName, setFormName] = useState("");
	const [formBusinessName, setFormBusinessName] = useState("");
	const [formCity, setFormCity] = useState("");
	const [formPhone, setFormPhone] = useState("");
	const [formEmail, setFormEmail] = useState("");
	const [modalProductDesc, setModalProductDesc] = useState({});
	const [modalState, setModalState] = useState(false);

	useEffect(() => {

	}, []);


	let products_arr = [
		{
			product_id: 0,
			name: "Smart Link",
			short_desc: "Rupin Smart Link wirelessly connect and control a wide range of rupin smart devices.",
			description: "Rupin Smart Link wirelessly connect and control a wide range of rupin smart devices. It makes your home Rupin Smart. It does not require Home Internet Connection.",
			specification: ["Controls over 50 Appliances", "Wifi - 2.4 GHz"],
			img_url: ["https://rupin-media.s3.ap-south-1.amazonaws.com/Products/smart_link/latest_top_light.png"],
			thumbnail_url: "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/smart_link/latest_top_light.png",
			buy_links: {
				"amazon": "",
				"flipkart": "",
			},
			mrp:0,
			price: 0,
			currency: "INR",
		},
		{
			product_id: 0,
			name: "Switch Controller",
			short_desc: "Controls appliances. Upto 18 channels model available.",
			description: "Sits inside swith board, and controls the appliaces connected on the switch board. Change Light Brightness, Change Fan Speed, Control AC or TV, Even unlock the door, with Rupin Mobile App and Alexa voice command.",
			specification: ["Variants - 2,4,8,18 Channels", "Controls Fan Speed with mobile phone and voice command", "Power per channel - 2000 W", "Very low power consumption"],
			img_url: ["https://rupin-media.s3.ap-south-1.amazonaws.com/Products/switch_controller/v1_black.JPG"],
			thumbnail_url: "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/switch_controller/v1_black.JPG",
			buy_links: {
				"amazon": "",
				"flipkart": "",
			},
			mrp:0,
			price: 0,
			currency: "INR",
		},
		{
			product_id: 0,
			name: "Rupin Motion Sensor",
			short_desc: "Automate your lights with motion sensing device.",
			description: "This motion sensing device lets you control lights and other appliaces on motion detection. Best used in Bathrooms, Corridors, Night light etc. Save energy freely with Rupin Motion sensor.",
			specification: ["Operating volt : AC 220-250v 50hz-60hz", "Load power : up to 1200 W.", "Max Ambient light : 3-2000lux (adjustable)", "Load type : incandescent lamp, led light, CFL, fans, etc.", "HF system: 5.8 Ghz cw radar,ism band", "Detecting Distance : 0~8m", "Detecting angle : 360 degree.", "Installation height: 2.2-4 Mtrs.", "Delay : 8 seconds -12 minutes (adjustable)"],
			img_url: ["https://rupin-media.s3.ap-south-1.amazonaws.com/Products/motion_sensor/motion+sensor+product.jpg", "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/motion_sensor/motion+sensor+detail1.jpg", "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/motion_sensor/motion+senor.jpg", "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/motion_sensor/IMG_8465(1).jpg", "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/motion_sensor/IMG_8287.jpg"],
			thumbnail_url: "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/motion_sensor/motion+sensor+product.jpg",
			buy_links: {
				"amazon": "",
				"flipkart": "",
			},
			mrp:0,
			price: 0,
			currency: "INR",
		},
		{
			product_id: 0,
			name: "Rupin Wardrobe Sensor",
			short_desc: "Controls wardrobe lights on motion detection.",
			description: "Save power on wardrobe lights with Rupin Wardrobe sensors. This sensing device turns on wardrobe lights when wardrobe is opened, and then turns off the lights automatically when wardrobe is closed.",
			specification: ["Technology - Infrared ( IR ) Sensor", "Operating volt : AC 220-240v 50hz-60hz.", "Load power : up to 500w", "Load : Incandescent lamp ( max 500W ), Led lights( max 200W ).", "IP Rating : IP20", "Detection Range : 5-6 cms.", "The Infrared Sensor is easy to install and does not require any specialized tools or equipment. - The device is designed to be durable and long-lasting, ensuring that it provides reliable performance over an extended period."],
			img_url: ["https://rupin-media.s3.ap-south-1.amazonaws.com/Products/wardrobe_sensor/IMG_8827.jpg", "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/wardrobe_sensor/IMG_8859(1).jpg", "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/wardrobe_sensor/IMG_8926.jpg", "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/wardrobe_sensor/IMG_8949.jpg"],
			thumbnail_url: "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/wardrobe_sensor/IMG_8827.jpg",
			buy_links: {
				"amazon": "",
				"flipkart": "",
			},
			mrp:0,
			price: 0,
			currency: "INR",
		},
		{
			product_id: 0,
			name: "Rupin Curtain Automation",
			short_desc: "Control your curtain anytime from anywhere with Rupin Curtain Automation Solution.",
			description: "Control your curtain anytime from anywhere with Rupin Curtain Automation Solution.",
			specification: ["Wifi and remote controlled", "Open / Close Curtain with remote / Rupin Curtains mobile app", "Schedule curtains for open and close", "Torque - 1.2 NM, 2.5 NM", "Input Voltage - 220 VAC", "Warranty - 5 years"],
			img_url: ["https://rupin-media.s3.ap-south-1.amazonaws.com/Products/curtain_automation/curtain+motor1.png", "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/curtain_automation/WhatsApp+Image+2023-10-06+at+22.38.36.jpeg"],
			thumbnail_url: "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/curtain_automation/curtain+motor1.png",
			buy_links: {
				"amazon": "",
				"flipkart": "",
			},
			mrp:0,
			price: 0,
			currency: "INR",
		},
		{
			product_id: 0,
			name: "Rupin LED Strips",
			short_desc: "Give special aesthetics to your home with Rupin LED Strips.",
			description: "Rupin LED Strips comes in many colors and LED Density ( 240 LEDs / mtr, 120 LEDs / mtr ). Our RGB LED Lights can controlled with mobile phone and remote. Set your room for every mood with Rupin LED Strips.",
			specification: ["Varients - 240 LEDs / mtr, 120 LEDs / mtr", "Colors : Warm White, Cold White, Natural White, Blue, Red, Amber and more.", "Length - 5 Mtrs Roll", "Operatig Voltage - 12V. Works best with Rupin Power Supply", "Rated Power - 47W, 24W"],
			img_url: [ "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/led_strips/rupin+front.jpg", "https://rupin-media.s3.ap-south-1.amazonaws.com/front-back.png", "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/led_strips/1382.jpg"],
			thumbnail_url: "https://rupin-media.s3.ap-south-1.amazonaws.com/Products/led_strips/rupin+front.jpg",
			buy_links: {
				"amazon": "",
				"flipkart": "",
			},
			mrp:0,
			price: 0,
			currency: "INR",
		},
		{
			product_id: 0,
			name: "Rupin Power Supply",
			short_desc: "High Quality General Purpose AC/DC Switch Mode Power supply.",
			description: "High Quality general purpose AC/DC switch mode power supply. Highly efficent, reliable and low power losses. It can be used with Rupin LED Strips, Automation controls, CCTC, Staircase lights and many more devices.",
			specification: ["Input Voltage : AC 160-260V ", "Input Frequency : 50/60Hz", "Output Voltage : DC 12V", "Output Varients : 2A, 3A, 5A, 10A, 15A, 20A, 25A and more.", "Cooling by Free Air Convection", "Long life - MTBF > 50,000 Hours" ],
			img_url: ["https://rupin-media.s3.ap-south-1.amazonaws.com/Rupin+Round+logo.png"],
			thumbnail_url: "https://rupin-media.s3.ap-south-1.amazonaws.com/Rupin+Round+logo.png",
			buy_links: {
				"amazon": "",
				"flipkart": "",
			},
			mrp:0,
			price: 0,
			currency: "INR",
		},

	];


	function openProductDesc( product ){
		console.log("Open Product Description");
		setModalProductDesc( product );
		setModalState(true);
	}

	return (
		<>
			<div className="App">
				<Header />
			</div>

			<section className="products-cards-section">
				<div className="container">
					<div className="row">
						{
							products_arr.map((product, index) => {
								return <>
									<div key={index} className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
										<div className="product_card" onClick={ () => { openProductDesc( product ) } }>
											<div className="cardThumbArea">
												<img src={ product['thumbnail_url'] }/>
											</div>
											<div className="cardDescArea">
												<h1>{product.name}</h1>
												<p>{product.short_desc}</p>
											</div>
										</div>
										<br/>
									</div>
								</>
							})
						}
					</div>
				</div>
			</section>
			<Footer />

			<ProductDescriptionModal productDesc={ modalProductDesc } modalState={modalState} setModalState={setModalState} />
		</>

	);


}

import React, { useState } from "react";
import axios from "axios";
import Apiendpoints from "../ApiEndpoints";
import "./css/team.css";

export default function Team(props) {
	return <>
		<section className="our-products">
			<div className="container">
				<div className="row">
					<h1>Our Team</h1>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-lg-1 col-md-1"></div>
					<div className="col-lg-4 col-md-5 p-5">
						<img
							src="/assets/images/alok_2.jpg"
							alt="Alok Ranjan"
							className="team-image"
						/>
						<div className="team-description">
							<h2>Alok Ranjan</h2>
							<h3>Tech Guy</h3>
							<h3>IIT Indore Alumni</h3>
						</div>
					</div>
					<div className="col-lg-2 col-md-1"></div>
					<div className="col-lg-4 col-md-5 p-5">
						<img
							src="/assets/images/archit_2.jpg"
							alt="Archit"
							className="team-image"
						/>
						<div className="team-description">
							<h2>Archit Sharma</h2>
							<h3>Marketing Guy</h3>
							<h3>MBA in Marketing</h3>
						</div>
					</div>
					<div className="col-lg-1 col-md-1"></div>
				</div>
			</div>
		</section>
	</>
}


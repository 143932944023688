import React, { useState, useEffect } from "react";
import axios from "axios";
import Apiendpoints from "../ApiEndpoints";
import "./css/productDescriptionModal.css";

export default function ProductDescriptionModal(props) {

	const [galleryImgIndex, setGalleryImgIndex] = useState(0);

	function closeModal() {
		props.setModalState(false);
	}
	let productDesc = props.productDesc;

	useEffect(() => {
		console.log(props);
		setGalleryImgIndex(0);
		return () => {
		}
	}, [props.modalState])

	function galleryPrev() {
		console.log(galleryImgIndex - 1);
		let prevIndex = 0;
		if (galleryImgIndex <= 0) {
			prevIndex = props.productDesc['img_url'].length - 1
		}
		else {
			prevIndex = galleryImgIndex - 1;
		}
		setGalleryImgIndex(prevIndex);
	}

	function galleryNext() {
		console.log(galleryImgIndex + 1);
		if (props.productDesc['img_url'].length === galleryImgIndex + 1) {
			setGalleryImgIndex(0);
		}
		else {
			setGalleryImgIndex(galleryImgIndex + 1);
		}
	}

	return <>
		{
			props.modalState ? <>
				<div className={`productDescModal`}>
					<div className="modalContainer">
						<div className="closeButton" onClick={closeModal}>
							<img src={"/assets/icons/close-50.png"} />
						</div>
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">

									<div className="productGalleryContainer">
										<div className="leftArrow" onClick={galleryPrev}>
											<img src="/assets/icons/arrowLeft-50.png" />
										</div>
										<img src={props.productDesc['img_url'][galleryImgIndex]} />
										<div className="rightArrow" onClick={galleryNext}>
											<img src="/assets/icons/arrowRight-50.png" />
										</div>
									</div>

								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
									<div className="productDescContainer">
										<h1>{productDesc['name']}</h1>
										<p>{productDesc['description']}</p>
										{
											productDesc['price'] === 0 ? <>
											</> : <>
												<p className="productPrice">Price : {
													productDesc['price'] != productDesc['mrp'] ? <>
														<span><s>{productDesc['mrp']}</s><h1>{productDesc['price']}</h1></span>
													</> : <>
														<h1>{productDesc['price']}</h1>
													</>
												}{productDesc['currency']} </p>
											</>
										}

										<div className="buyNowLinkContainer">
											{
												productDesc['buy_links']["amazon"] != "" ? <>
													<a className="buyNowButton buyNowAmazon" target="_blank" href={productDesc['buy_links']['amazon']}>
														<img src="/assets/icons/amazon.svg" />
													</a>
												</> : <></>
											}
											{
												productDesc['buy_links']["amazon"] != "" ? <>
													<a className="buyNowButton buyNowFlipkart" target="_blank" href={productDesc['buy_links']['flipkart']}>
														<img src="/assets/icons/flipkart.png" />
													</a>
												</> : <></>
											}


										</div>
										<div className="productSpecificationArea">
											<ul class="specificationList">
												{
													productDesc['specification'].map((spec, index) => {
														return <>
															<li key={index}>{spec}</li>
														</>
													})
												}
											</ul>

										</div>
										<div className="contactUsButton">
												<a href="/contact-us">Talk to Us</a>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</> : <>
			</>
		}

	</>
}


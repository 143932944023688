import React, { useState } from "react";
import axios from "axios";
import Apiendpoints from "../ApiEndpoints";
import "./css/contactus.css";
import Loader from "react-js-loader";
import LoaderScreen from "./LoaderScreen";

export default function ContactUs(props) {
	const [contact_us_form_state, update_contact_us_form_state] = useState({ name: "", phone: "", email: "", city: "", select_topic: "", message: "" });
	const [contactUsStatusText, setContactUsStatusText] = useState("");
	const [loaderFlag, setLoaderFlag] = useState(false);
	const apiEndpoints = new Apiendpoints();

	function update_contact_us_form(key, value) {
		let contact_us_form_state_copy = JSON.parse(JSON.stringify(contact_us_form_state));
		try {
			contact_us_form_state_copy[key] = value;
		}
		catch (err) {
			console.log(err);
		}

		update_contact_us_form_state(JSON.parse(JSON.stringify(contact_us_form_state_copy)));
	}

	function validate_contact_us_form() {
		let response = {};

		response['status'] = 0;
		response['message'] = "";
		response['payload'] = {};

		let name = contact_us_form_state.name;
		let phone = contact_us_form_state.phone;
		let email = contact_us_form_state.email;
		let city = contact_us_form_state.city;
		let select_topic = "Contact Us Form Submission";
		let topic_code = "CONTACTFORMSUBMIT";
		let message = contact_us_form_state.message;

		let payload = {};

		if (name !== undefined && name !== "") {
			payload['name'] = name;
			response['status'] = 1;
		}
		else {
			response['message'] = "Enter Name";
			return response;
		}

		if (phone !== undefined && phone !== "") {
			payload['phone'] = phone;
			response['status'] = 1;
		}
		else {
			response['message'] = "Enter Phone";
			return response;
		}

		// if ( email != undefined && email != "" ){
		//   payload['email'] = email;
		// }
		// else {
		//   response['message'] = "Enter Email";
		// }

		if (city !== undefined && city !== "") {
			payload['city'] = city;
			response['status'] = 1;
		}
		else {
			response['status'] = 0;
			response['message'] = "Enter City";
			return response;
		}

		// if ( select_topic != undefined && select_topic != "" ){
		//   payload['topic'] = select_topic;
		// }
		// else {
		//   response['message'] = "Select Option";
		// }

		// if ( message != undefined && message != "" ){
		//   response['status'] = 1;
		//   payload['message'] = message;
		// }
		// else {
		//   response['message'] = "Enter Message";
		// }
		payload['email'] = email;
		payload['topic'] = select_topic;
		payload['message'] = message;
		payload['topic_code'] = topic_code;
		response['payload'] = payload;
		return response;
	}

	function send_query() {
		let form_validation = validate_contact_us_form();
		let data = {};
		if (form_validation.status === 1) {
			data = form_validation.payload;
			let endpoint = apiEndpoints.SEND_CONTACT_QUERY;
			let config = {
				headers: {

				},
			};
			setLoaderFlag(true);
			axios
				.post(endpoint, data, config)
				.then((res) => {
					console.log(res);
					setContactUsStatusText("Thanks for contacting us. We will connect with you soon.");
					setLoaderFlag(false);
				})
				.catch((error) => {
					console.log(error);
					setLoaderFlag(false);
				});
		}
		else {
			console.log(form_validation);
		}
	}
	return <>
		<section id="contact_us" className="connect-form container">
			<div className="row align-items-center">
				<div className="col-lg-6 col-md-6 col-12 p-5 d-flex-column align-items-center">
					<h1>Contact us</h1>
					<div className="row">
						<div className="col-12">
							<h3>Corporate Office :</h3>
							<div className="col-12 d-flex">
								<img src="/assets/icons/globe.svg" alt="mail" />
								<h4 className="p-3">
									Otomator Technologies Pvt Ltd <br />
									145, Canal S Rd,
									<br /> Metropolitan Co-Operative Housing Society
									<br />
									Limited, Tangra, Kolkata, West Bengal 700105
								</h4>
							</div>
						</div>
						<br />
					</div>
					<div className="col-12">
						<h3>Registered Office :</h3>
						<div className="col-12 d-flex">
							<img src="/assets/icons/globe.svg" alt="mail" />
							<h4 className="p-3">
								Anita Kunj, Sitabpur Talla<br />
								Kotdwara, Pauri Garhwal
								<br /> 246149, Uttarakhand
								{/* <br /> */}
								{/* Limited, Tangra, Kolkata, West Bengal 700105 */}
							</h4>
						</div>
					</div>
					<br /><br /><br />
					<br />
					<br />
					<br />
					<div className="row">
						<div className="col-lg-6 md-12 sm-12 d-flex">
							<img src="/assets/icons/mail.svg" alt="mail" />
							<h4 className="p-3">alok@rupin.ai</h4>
						</div>
						<div className="col-lg-6 md-12 sm-12 d-flex">
							<img src="/assets/icons/phone.svg" alt="mail" />
							<h4 className="p-3">+91 9826 311996</h4>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6 md-12 sm-12 d-flex">
							<img src="/assets/icons/mail.svg" alt="mail" />
							<h4 className="p-3">archit@rupin.ai</h4>
						</div>

						<div className="col-lg-6 md-12 sm-12 d-flex">
							<img src="/assets/icons/phone.svg" alt="mail" />
							<h4 className="p-3">+91 82873 67057</h4>
						</div>
					</div>
					<br />
					<br />
				</div>

				<div className="col-lg-6 col-md-6 col-12 p-5">
					<div className="div">
						<h1>Let’s get in Touch</h1>
					</div>
					<div className="row">
						<div className="col-lg-6 p-3">
							<h4>Name</h4>
							<input value={contact_us_form_state.name} onChange={(e) => { update_contact_us_form('name', e.target.value) }} />
						</div>
						<div className="col-lg-6 p-3">
							<h4>Phone</h4>
							<input value={contact_us_form_state.phone} onChange={(e) => { update_contact_us_form('phone', e.target.value) }} />
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 p-3">
							<h4>Email</h4>
							<input value={contact_us_form_state.email} onChange={(e) => { update_contact_us_form('email', e.target.value) }} />
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 p-3">
							<h4>City</h4>
							<input value={contact_us_form_state.city} onChange={(e) => { update_contact_us_form('city', e.target.value) }} />
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 p-3">
							<h4>Message</h4>
							<textarea rows="10" value={contact_us_form_state.message} onChange={(e) => { update_contact_us_form('message', e.target.value) }} />
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 p-3 align-items-center justify-content-end">
							<p className="contactUsStatusText">{contactUsStatusText}</p>
							<button className="connect-form-btn" onClick={send_query}>Send Message</button>
						</div>
					</div>
				</div>
			</div>
			<div className="col-lg-4 col-md-4 p-3 align-content-center"></div>
		</section>
		{
			loaderFlag ? <>
				<LoaderScreen />
			</> : <></>
		}

	</>
}


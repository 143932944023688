// import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./css/App.css";
import Home from "./pages/Home";
import DesignClub from "./pages/DesignClub";
import DesignClubOnboarding from "./pages/DesignClubOnboarding";
import Products from "./pages/Products";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import ContactUsPage from "./pages/ContactUsPage";
// import "bootstrap/dist/css/bootstrap.min.css";

function App() {
	// useEffect(() => {
	//   const scroll = new LocomotiveScroll({
	//     el: document.querySelector(".App"),
	//     smooth: true,
	//   });
	// }, []);
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="products" element={<Products />} />
				<Route path="design-club" element={<DesignClub />} />
				<Route path="design-club-onboarding" element={<DesignClubOnboarding />} />
				<Route path="privacy-policy" element={<PrivacyPolicy />} />
				<Route path="terms-and-conditions" element={<TermsAndConditions />} />
				<Route path="contact-us" element={<ContactUsPage/>} />
				{/* <Route path="error" element={<Error />} /> */}
			</Routes>
		</BrowserRouter>
		// <div className="App">
		//   <Home />
		// </div>
	);
}

export default App;

import React, { useEffect, useState } from "react";
import ContactUs from "../components/ContactUs";
import Header from "../components/Header";
import "./css/DesignClubOnboarding.css";
import Footer from "../components/Footer";

export default function DesignClubOnboarding() {

	const [ formName, setFormName ] = useState("");
	const [ formBusinessName, setFormBusinessName ] = useState("");
	const [ formCity, setFormCity ] = useState("");
	const [ formPhone, setFormPhone ] = useState("");
	const [ formEmail, setFormEmail ] = useState("");

	useEffect(() => {

	}, []);

	function submitForm(){
		console.log("Submit Form");
	}

	return (
		<>
			<div className="App">
				<Header />
			</div>

			<section className="dc_onboarding_form">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="onboarding_form">
								
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>

	);


}

import React, { useEffect, useState } from "react";


import "./css/header.css";

export default function ContactUs(props) {

	let menu_options_arr = [
		{
			name: "Home",
			url: "/#home"
		},
		{
			name: "Features",
			url: "/#features"
		},
		{
			name: "Products",
			url: "/products"
		},
		{
			name: "Mobile App",
			url: "/#mobile_app"
		},
		{
			name: "How we work",
			url: "/#how_we_work"
		},
		{
			name: "Design Club",
			url: "/design-club"
		},
		{
			name: "Contact Us",
			url: "/contact-us"
		},

	];

	const [ui_state, update_ui_state] = useState();
	const [side_menu_state, update_side_menu_state] = useState(0);

	function open_side_menu() {
		update_side_menu_state(1);
	}
	function close_side_menu() {
		update_side_menu_state(0);
	}

	function handleResize(e) {
		let window_width = window.innerWidth;
		// console.log( { ui_state, side_menu_state } );
		if (window_width < 768 && ui_state === "D") {
			update_ui_state("M");
		}
		else if (window_width >= 768 && ui_state === "M") {
			update_ui_state("D");
		}
	}

	window.addEventListener("resize", handleResize);

	useEffect(() => {
		let window_width = window.innerWidth;

		if (window_width < 768) {
			update_ui_state("M");
			// console.log("This is mobile");
		}
		else if (window_width >= 768) {
			update_ui_state("D");
			// console.log("This is desktop");
		}
	}, []);

	return <>
		<section className="menu-header">
			<div className="row">
				<div className="col-12 col-lg-4 col-md-4 header_left_side">
					<a className="header_logo_area" href="/">
						<img src="/assets/images/logo.svg" alt="rupin" />
					</a>
					<div className="header_contact_info_area">
						<a className="d-flex" href="mailto:info@otomator.com">
							<img src="/assets/icons/mail-white.svg" alt="mail" />
							<h4 className="p-3">info@rupin.ai</h4>
						</a>
						<a className="d-flex" href="tel:+91 9826 311996">
							<img src="/assets/icons/phone-white.svg" alt="mail" />
							<h4 className="p-3">+91 82873 67057</h4>
						</a>
					</div>

				</div>
				<div className="col-lg-8 col-md-8 col-12 align-self-center">
					<div className="menu-items header_menu_right_side">
						{
							menu_options_arr.map((menu_option, index) => {
								return <>
									<h2 key={index}><a href={menu_option['url']}>{menu_option['name']}</a></h2>
								</>
							})
						}
					</div>
				</div>

			</div>
			{
				ui_state === "M" && side_menu_state === 0 ? <>
					<button onClick={open_side_menu} className="hamburger_menu"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAABRElEQVRYhe2XPUsDQRCGn9kLd6gB01tJCBYJmE5/gVVqC1Nr/lNqY2Fv4y/QLqAGJAQrez/Ojz1yNyk0WJk71nBrkbdcZuYZ5mUYVviW6jjCSg+RI6AFbLBcvQG3qA6ItC/SsAACoO/3WwSVC2B3ydDfNCSddmR951FUxxGJuS4R/tNEmO0brPQ8wAHa2ODYINL1AP+SZF1RO3kFqp5aiI1HOEDVeIQDUMmNeDiAZOJWPazD9uXCkPwJuMIL5nq3IL+BsO5evUCuqJ2oO+Hv8m5B/hY8n0P65FY9qMHm4cKQ/Am4wgvmercgv4Gg5l69QO5qC1a3wLsFq1vwLyyIPfJfDDDyx9eRQfXUH98M5l+zK6BdMn5I+LlnRBqWdNoBhqXC02lHpJnI/EX1LsSunSBZF6TF8j8sMcgNyhnRR1+kmQDMAOmXbHVJgu3cAAAAAElFTkSuQmCC" /></button>
				</> : <>
				</>
			}


			<div className={(ui_state === "M" && side_menu_state === 1) ? "side_menu_area show_side_menu" : "side_menu_area hide_side_menu"}>

				<button onClick={close_side_menu} className="close_side_menu"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABfUlEQVRIie2WwUrDQBCG/91pL7o5NuAbeKhPEKi0tQcVX1ZQQaxgIU+gh76BUo9JvSS766EVYrLZTKIilP7HZP/5spmdmQX22nUJ18MkVqGxdAsAUuiLIEpXbYIW/FYKfenyV8DJQg20pLkATraPlj2TTQ5HH68sKNMvy0Yj6a5gAoDjTPbvk1iFjdBYhVrSY9mfy/51eW0F7JIAhtrS3AdPYhVqS3MBDDkxK2Bp9LkFnl1wY+lpvTg4qkAXaqAtPdRAl5TlV454jq+v5ulboGLOmtZSlo/VeP3GAnPhBjLvAvWCAX/eLPCyDeB8R0JPfWXoBQONO3fJu1M2uCWcBWWDmXA2FGDWMVeCLHsjv/6rue31Tw4XB/5v5VSb46Y22MvyGRk9adteC2tqoMyO1Ka9esFdem8XOGceA9jkTQp96qrTYJS+k9BnX3kvqfs85hyWIEpXJPS0Bl4RZx4ve1k+49y7gihdOQ5ci3m8uazdAEDdZc2nn/r32g19AiJpINrhL9hpAAAAAElFTkSuQmCC" /></button>

				{
					menu_options_arr.map((menu_option) => {
						return <>
							<a href={menu_option['url']}>{menu_option['name']}</a><br /><br />
						</>
					})
				}

			</div>
		</section>
		<br /><br /><br /><br /><br />
	</>
}
import React, { useEffect, useState } from "react";


import "./css/footer.css";

export default function Footer(props) {
	return <>
		<section className="footer">
			<div className="container">
				<div className="row align-items-start">
					<div className="col-lg-4 col-md-4 d-flex align-items-center p-4">
						<div className="col-lg-3 col-md-4">
							<img src="/assets/images/logo.svg" alt="rupin" />
							{/* <h4>R U P I N</h4> */}
						</div>
						<h3>Building Next Generation Lifestyle</h3>
					</div>
					<div className="col-lg-2 col-md-2 d-flex p-4 "></div>

					<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
						<h3>Rupin.ai</h3>
						<div className="row footer_options_list">
							<a className="col-lg-4" href="#home">Home</a>
							<a className="col-lg-4 md-6 sm-6" href="/#features">Features</a>
							<a className="col-lg-4 md-6 sm-6" href="/#how_we_work">How we Work</a>
							<a className="col-lg-4 md-6 sm-6" href="/products">Products</a>
							<a className="col-lg-4 md-6 sm-6" href="/#mobile_app">Mobile App</a>
							<a className="col-lg-4 md-6 sm-6" href="/design-club">Design Club</a>
							<a className="col-lg-4 md-6 sm-6" href="/privacy-policy">Privacy Policy</a>
							<a className="col-lg-4 md-6 sm-6" href="/terms-and-conditions">Terms and Conditions</a>
						</div>
						<br />
						<br />
						<br />
						<div className="row">
							<div className="col-12 footer_social_media">
								<a href="https://www.facebook.com/RupinLife" target={"_blank"}><img src="/assets/icons/facebook.svg" /></a>
								<a href="https://www.instagram.com/rupin.ai/" target={"_blank"}><img src="/assets/icons/insta.svg" /></a>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="row">
            <div className="col-lg-4 col-md-4 d-flex p-4 ">
              <h4>
                145, Canal S Rd, Metropolitan Co-Operative Housing Society
                Limited, Tangra, Kolkata, West Bengal 700105
              </h4>
            </div>
            <div className="row p-4 ">
              <h4>
                Phone: +91 9826 311996
                <br /> Email: info@otomator.com
              </h4>
            </div>
          </div> */}
			</div>
		</section>
	</>
}
import { useEffect } from "react";
import "./Benifits.css";

export default function Benifits(props) {
    useEffect(() => {

    }, []);
  return (
    <section className="benifits-outer col-lg-4 col-md-6 col-sm-12 ">
      <div className="image-benifits">
        <img alt="benifits" src={props.image} />
      </div>
      <h2 data-scroll>{props.title}</h2>
      <p data-scroll>{props.text}</p>
    </section>
  );
}

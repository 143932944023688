import React, { useEffect } from "react";
import Header from "../components/Header";
import "./css/ContactUsPage.css";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";

export default function ContactUsPage() {
	useEffect(() => {

	}, []);

	return (
		<div className="App">
			<Header />
			
			<ContactUs/>

			<Footer />
		</div>
	);
}
